<template>
  <v-card :style="{position: 'sticky !important',top: `${stickyValue}px`,transition: 'top 0.2s linear',}" id="config">
    <!--  <v-card>-->
    <div class="config-container menu" ref="customMenu">
      <v-card-title v-text="$t('configuration.title')" style="font-size: 16px;"></v-card-title>
      <window
        class="mt-1 mb-1 main-svg"
        :id="`window_configuration`"
        :image-url="decorUrl()"
        :opening-code="configItems.openings ? configItems.openings.opening_code : ''"
        :opening-min-width="configItems.openings ? configItems.openings.min_width : 200"
        :opening-max-width="configItems.openings ? configItems.openings.max_width : 200"
        :opening-min-height="configItems.openings ? configItems.openings.min_height : 200"
        :opening-max-height="configItems.openings ? configItems.openings.max_height : 200"
        :splitting="configItems.dimensions ? configItems.dimensions.splitting : []"
        :total-width="configItems.dimensions ? configItems.dimensions.total_width : 1000"
        :total-height="configItems.dimensions ? configItems.dimensions.total_height : 1000"
        :use-textures="true"
        :use-input-fields="false"
        :show-connecting-lines="true"
        :show-infos-in-segment="true"
        :show-segment-numbers="true"
      />
      <div
        v-for="(item, key, index) in listConfigItems"
        :key="index"
        @mouseover="changeCursorStyle"
        @mouseleave="resetCursorStyle"
        :style="cursorStyle"
        @click="scrollToAnchor(key)"
        :class="['hover-effect']"
      >
        <div
          v-if="
          !isCategoryHidden(key) &&
          item &&
          key !== 'uuid' &&
          key !== 'dimensions' &&
          key !== 'frame_extensions' &&
          key !== 'frame_extension_above' &&
          key !== 'frame_extension_below' &&
          key !== 'frame_extension_left' &&
          key !== 'frame_extension_right' &&
          key !== 'finalConfig' &&
          key !== 'finalConfigJson' &&
          key !== 'discount' &&
          key !== 'PriceCalculations' &&
          !isMultipleChoice(key)
        "
          class="py-2 px-4"
          style="font-size: 0.8rem"
        >
          <div class="" style="font-size: 12px">
            <span class="font-weight-bold">{{ $store.getters["productData/labelMenu"](key) }}:</span> <span>{{ item.label }}</span>
          </div>

          <div v-if="key === 'glazing_bars'" style="font-size: 12px">
            <div class="font-weight-bold mt-2">
              {{ $t("glazing_bars_divisions.headingConfig") }}
            </div>
            <div
              v-for="(_, index) in windowSectionWidths"
              :key="`reihe_${index}`"
            >
              {{ $t("dimension.segment") }} {{ index + 1 }}:
              {{
                customerConfig.glazing_bars_divisions[index]
                  ? customerConfig.glazing_bars_divisions[index].label
                  : $t("defaultTexts.missing")
              }}
            </div>
          </div>
        </div>
        <div
          v-if="isMultipleChoice(key) && existActiveItems(key)"
          class="py-2 px-4"
          style="font-size: 12px"
        >
          <div class="font-weight-bold" style="font-size: 12px">
            {{ $store.getters["productData/labelMenu"](key) }}:
          </div>
          <div v-for="(equipment, index) in item" :key="`equipment_${index}`">
            <div v-if="equipment">
              {{ equipment.label }}
            </div>
          </div>
        </div>
        <div v-if="item && key === 'frame_extensions' && existFrameExtensionLabel" class="py-2 px-4">
          <div style="font-size: 12px">
            <span class="font-weight-bold">{{ $t("frame_extensions.labelMenu") }}:</span>
          </div>
          <div style="font-size: 12px">
            <span class="font-weight-bold">{{ $t("frame_extensions_above.labelMenu") }}:</span> <span>{{ getLabelAbove }}</span>
          </div>
          <div style="font-size: 12px">
            <span class="font-weight-bold">{{ $t("frame_extensions_below.labelMenu") }}:</span> <span>{{ getLabelBelow }}</span>
          </div>
          <div style="font-size: 12px">
            <span class="font-weight-bold">{{ $t("frame_extensions_left.labelMenu") }}:</span> <span>{{ getLabelLeft }}</span>
          </div>
          <div style="font-size: 12px">
            <span class="font-weight-bold">{{ $t("frame_extensions_right.labelMenu") }}:</span> <span>{{ getLabelRight }}</span>
          </div>
        </div>

        <div v-if="item && item.total_width" class="py-2 px-4" style="font-size: 0.8rem">
          <div style="font-size: 12px">
            <span class="font-weight-bold">{{ $store.getters["productData/labelMenu"](key) }}:</span> {{ $t("dimension.width") }}: {{ item.total_width }}mm x {{ $t("dimension.height") }}: {{ item.total_height }}mm
          </div>
          <div v-for="(reihe, index) in item.splitting" :key="`reihe_${index}`">
            <div v-for="(segment, idx) in reihe.section_widths" :key="`segment_${idx}`">
              <div v-if="reihe.section_widths.length > 1 || item.splitting.length > 1">
                {{ $t("dimension.segment") }} {{ idx + index + 1 }}: {{ segment }}mm x
                {{ reihe.height }}mm
              </div>
            </div>
          </div>
        </div>

        <v-divider
          v-if="
          !isCategoryHidden(key) &&
          item &&
          key !== 'frame_extension_above' &&
          key !== 'frame_extension_below' &&
          key !== 'frame_extension_left' &&
          key !== 'frame_extension_right' &&
          key !== 'finalConfig' &&
          key !== 'finalConfigJson' &&
          key !== 'uuid' &&
          key !== 'PriceCalculations'
        "
        ></v-divider>
      </div>
      <div class="py-2 px-4" style="font-size: 12px; text-align: center !important;"
           v-if="configItems.discount.percent > 0">
        <h2 v-if="isCartOlymp() !== '1'">
          {{ configItems.discount.percent + $t("configuration.discount") }}
        </h2>
        <span style="font-size: 16px;" v-if="isCartOlymp() !== '1'">
        {{ $t("configuration.oldPrice") }}
        <span style="font-size: 16px; text-decoration: line-through;">
          {{ $n(configItems.discount.oldPrice, "currency") }}
        </span>
      </span>
      </div>
      <v-card-actions>
        <input type="hidden" name="js-setup-data" :value="setupJsonString" />
        <input
          type="hidden"
          name="js-product-data"
          :value="productDataJsonString"
        />
        <input
          type="hidden"
          name="js-reload-product-id"
          :value="setupReloadProductId"
        />
        <input
          type="hidden"
          name="js-configuration-data"
          :value="configurationJsonString"
        />
        <input type="hidden" name="js-description-text" :value="configString" />
        <input type="hidden" name="js-description-json" :value="configStringJson" />
        <input type="hidden" name="js-price-calculations" :value="PriceCalculations"/>
        <input type="hidden" name="js-item-number" :value="ItemNumber" />
        <input type="hidden" name="js-image-svg" id="js-config-svg" value="" />
        <input type="hidden" name="uuid" id="js-uuid" value="" />
        <input type="hidden" name="additional-products" id="js-additional-products" value="" />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              :disabled="configItems.mainPrice.value === 0"
              dark
              v-bind="attrs"
              v-on="on"
              class="mx-auto mb-2 letwork-button-orange"
              data-product-id="2"
              min-width="125px"
              width="100%"
              @click="addToCard"
              id="add_to_cart_button_windows_configurator"
            >
              <v-icon>mdi-cart</v-icon>
              <span
                v-text="$n(configItems.mainPrice.value, 'currency')"
                class="ml-2"
              ></span>
            </v-btn>
          </template>
          <span v-text="$t('configuration.buttonToCart')" v-if="cartTitel() === ''"></span>
          <span v-text="cartTitel()" v-if="cartTitel() !== ''"></span>
        </v-tooltip>
      </v-card-actions>
      <div class="py-2" style="text-align: center !important; padding-top: 0 !important; margin-top: -12px;">
      <span style="font-size: 11px;" v-if="exclusiveVat() !== '1'">
        {{ $t("configuration.infoText") }}
      </span>
        <span style="font-size: 11px;" v-if="exclusiveVat() === '1'">
        {{ $t("configuration.infoTextExVat") }}
      </span>
      </div>
      <!--    <v-card-actions>-->
      <!--      <v-btn color="info" @click="dumpCustomerConfig" class="mx-auto"-->
      <!--        >Dump Customer Config-->
      <!--      </v-btn>-->
      <!--    </v-card-actions>-->

    </div>
  </v-card>
</template>
<script>
import Window from "@/components/Window";
import { mainMixin } from "@/mixins/mainMixin";
//import BackendService from "../services/BackendService";

export default {
  name: "Configuration",
  mixins: [mainMixin],
  components: {
    Window
  },
  data() {
    return {
      configHeight: 0,
      windowHeight: 0,
      form: {
        product_id: "2",
        product_quantity: "1",
        sys_lang_id: "2",
        is_ajax: ""
      }
    };
  },
  computed: {
    existFrameExtensionLabel() {
      let FrameExtensionLabel = this.configItems.frame_extension_above?.label;
      return !!(FrameExtensionLabel && FrameExtensionLabel !== "undefined");
    },
    getLabelAbove() {
      return this.configItems.frame_extension_above?.label;
    },
    getLabelBelow() {
      return this.configItems.frame_extension_below?.label;
    },
    getLabelLeft() {
      return this.configItems.frame_extension_left?.label;
    },
    getLabelRight() {
      return this.configItems.frame_extension_right?.label;
    },
    configItems() {
      return this.$store.state.configuration.customerConfig;
    },
    productData() {
      return this.$store.state.productData;
    },
    listConfigItems() {
      // filter out config items who doesnt belong to the list items
      return Object.keys(this.configItems)
        .filter(
          (key) =>
            key !== "mainPrice" &&
            key !== "thumbnail" &&
            key !== "glazing_bars_divisions"
        )
        .reduce(
          (newObj, currKey) => (
            (newObj[currKey] = this.configItems[currKey]), newObj
          ),
          {}
        );
    },
    setupReloadProductId() {
      return JSON.stringify(this.$store.state.setup.reloadProductId);
    },
    setupJsonString() {
      return JSON.stringify(this.$store.state.setup);
    },
    productDataJsonString() {
      return JSON.stringify(this.$store.state.productData);
    },
    configurationJsonString() {
      return JSON.stringify(this.$store.state.configuration);
    },
    configString() {
      return this.$store.state.configuration.customerConfig.finalConfig;
    },
    configStringJson() {
      return this.$store.state.configuration.customerConfig.finalConfigJson;
    },
    PriceCalculations() {
      return (
        this.$store.state.configuration.customerConfig.PriceCalculations
          .purchasing_price_1 +
        "|NEOX|" +
        this.$store.state.configuration.customerConfig.PriceCalculations
          .purchasing_price_2 +
        "|NEOX|" +
        this.$store.state.configuration.customerConfig.PriceCalculations
          .purchasing_price_3
      );
    },
    ItemNumber() {
      return this.$store.state.productData.item_number;
    },
    configHeading(item) {
      let heading;
      if (this.productData[item]) {
        heading = this.productData[item]?.menu?.[0]?.label_menu;
      }
      return heading;
    },
    stickyValue() {
      const difference = this.windowHeight - this.configHeight;
      return difference < 10 ? difference - 10 : 10;
    },
    windowSectionWidths() {
      return this.customerConfig.dimensions.splitting
        .map((s) => s.section_widths)
        .flat();
    }
  },
  methods: {
    dumpCustomerConfig() {
      //console.log(this.$store.state.configuration.customerConfig);
    },
    getWindowConfigHeight() {
      this.configHeight = document.querySelector("#config").offsetHeight;
      this.windowHeight = window.innerHeight;
    },
    async addToCard() {
      const useAddToCartModal = this.$store.getters["setup/getUseAddToCartModal"];
      if (useAddToCartModal === "1") {
        await this.openModal({ id: "modalAfterAddToCart", content: "" });
      }
      await this.$store.dispatch(
        "configuration/getFinalConfigFromApi",
        {},
        { root: true }
      );
    },
    isCategoryHidden(key) {
      let hidden = false;
      hidden = this.productData.model[key]?.hiddenCategory ?? false;
      return hidden;
    },
    isMultipleChoice(key) {
      return this.$store.state.productData.model[key]?.multipleChoice;
    },
    existActiveItems(key) {
      let object = this.$store.state.configuration.customerConfig[key];
      if (object) {
        for (const value of Object.values(object)) {
          if (value !== null) {
            return true;
          }
        }
      }
      return false;
    },
    isCartOlymp() {
      return this.$store.getters["setup/getCartOlymp"];
    },
    cartTitel() {
      return this.$store.getters["setup/getCartTitel"];
    },
    exclusiveVat() {
      return this.$store.getters["setup/getExclusiveVat"];
    }
  },
  watch: {
    configItems: {
      handler() {
        setTimeout(this.getWindowConfigHeight, 100);
      },
      deep: true
    }
  }
};
</script>
<style scoped>

.config-container {
  max-height: 95vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 10px; /* Um den Scrollbalken nicht den Text überdecken zu lassen */
}

/* Media Queries für Mobilgeräte */
@media (max-width: 960px) {
  .config-container {
    max-height: none;
    overflow-y: unset;
  }
}

.letwork-button-orange {
  background-color: #f59100 !important;
  color: #ffffff !important;
  font-size: 1.1rem !important;
  min-height: 2.875rem !important;
  padding: .5rem 1.25rem !important;
}

.hover-effect {
  transition: background-color 0.3s ease; /* Optional: fügt eine Übergangsanimation hinzu */
}

.hover-effect:hover {
  background-color: rgba(243, 113, 0, 0.13); /* Farbe anpassen für den gewünschten Hover-Effekt */
}

.v-text-field input {
  border: unset;
  text-align: left;
}


.v-application h2 {
  margin-bottom: unset !important;
  font-size: 20px !important;
}

.v-divider {
  margin: 0 !important;
}

.configurator__add-to-cart {
  //background: url(https://letwork.de/wp-content/themes/letwork/assets/img/icons/cart--alt--white.svg) no-repeat 0 50%;
  padding: 0 0 0 2.5rem;
}

</style>