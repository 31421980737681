<template>
  <div v-if="selectableItems.total > 0" class="mb-8">
    <section-header :item-type="itemType" />
    <div class="px-3 py-3">
      <v-row v-if="$store.getters['productData/descriptionImage'](itemType) && $store.getters['productData/descriptionImage'](itemType) !== 'undefined'">
        <v-col cols="3" class="pl-0">
          <v-img :src="imageURL($store.getters['productData/descriptionImage'](itemType))" contain max-height="150" style="margin-bottom: 20px;"></v-img>
        </v-col>
        <v-col cols="9">
          <div v-html="$store.getters['productData/descriptionShortMenu'](itemType)" style="font-size: 14px"></div>
        </v-col>
      </v-row>
      <v-row v-if="$store.getters['productData/descriptionImage'](itemType) == null || $store.getters['productData/descriptionImage'](itemType) === 'undefined'">
        <v-col>
          <div v-html="$store.getters['productData/descriptionShortMenu'](itemType)" style="font-size: 14px"></div>
        </v-col>
      </v-row>
    </div>
    <v-btn-toggle
      v-if="isOptional"
      v-model="showOptional"
      mandatory
      style="width: 100%"
      color="primary"
      class="d-flex mt-0 mb-10"
    >
      <v-btn
        :value="false"
        @click="toggleOptionalConfig(false)"
        :class="{'active-btn': !optionalConfigActive, 'inactive-btn': optionalConfigActive}"
        class="flex-grow-1"
      >
        {{ $t('optional.skip') }}
        <v-icon v-if="!optionalConfigActive" class="letwork-card-icon">mdi-check</v-icon>
      </v-btn>
      <v-btn
        :value="true"
        @click="toggleOptionalConfig(true)"
        :class="{'active-btn': optionalConfigActive, 'inactive-btn': !optionalConfigActive}"
        class="flex-grow-1"
      >
        {{ $t('optional.enable') }}
        <v-icon v-if="optionalConfigActive" class="letwork-card-icon">mdi-check</v-icon>
      </v-btn>
    </v-btn-toggle>
    <div v-if="!isOptional || (isOptional && showOptional)">
      <div>
        <v-col cols="12" lg="12">
          <v-col cols="12" lg="12" class="py-0 align-center">
            <v-select
              v-model="frame_extension_above"
              :items="selectableFrameExtensionsAbove.items"
              color="primary"
              :label="`${$t('frame_extensions.above_title')}`"
              disable-lookup
              hide-selected
              return-object
              item-text="label"
              @change="$root.$emit('sectionChanged')"
              clearable
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      v-text="data.item.label"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-select>
          </v-col>
        </v-col>
        <v-container class="py-0">
          <v-row no-gutters>
            <v-col cols="4" class="py-0 align-center" style="margin-top: 100px">
              <v-select
                v-model="frame_extension_left"
                :items="selectableFrameExtensionsLeft.items"
                color="primary"
                :label="`${$t('frame_extensions.left_title')}`"
                disable-lookup
                hide-selected
                return-object
                item-text="label"
                @change="$root.$emit('sectionChanged')"
                clearable
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        v-text="data.item.label"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
            </v-col>
            <v-col cols="4" class="align-center">
              <v-img :src="imageURL(image)" contain height="300"></v-img>
            </v-col>
            <v-col cols="4" class="py-0 align-center" style="margin-top: 100px">
              <v-select
                v-model="frame_extension_right"
                :items="selectableFrameExtensionsRight.items"
                color="primary"
                :label="`${$t('frame_extensions.right_title')}`"
                disable-lookup
                hide-selected
                return-object
                item-text="label"
                @change="$root.$emit('sectionChanged')"
                clearable
              >
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-subtitle
                        v-text="data.item.label"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-container>
        <v-col cols="12" lg="12">
          <v-col cols="12" lg="12" class="py-0 align-center">
            <v-select
              v-model="frame_extension_below"
              :items="selectableFrameExtensionsBelow.items"
              color="primary"
              :label="`${$t('frame_extensions.below_title')}`"
              disable-lookup
              hide-selected
              return-object
              item-text="label"
              @change="$root.$emit('sectionChanged')"
              clearable
            >
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-subtitle
                      v-text="data.item.label"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-select>
          </v-col>
        </v-col>
      </div>
    </div>
  </div>
</template>
<script>
import { mainMixin } from "@/mixins/mainMixin";
import SectionHeader from "@/components/SectionHeader";
//import Window from "@/components/Window";

export default {
  name: "FrameExtensions",
  components: { SectionHeader },
  props: {
    maxItems: {
      type: [Number, String],
      default: 100,
    },
    itemType: {
      type: String,
      required: true,
    },
    item: {},
    columns: {
      type: Number,
      default: 3,
    },
  },
  mixins: [mainMixin],
  data() {
    return {
      showAll: false,
      showOptional: false,
      optionalConfigActive: null
    };
  },
  computed: {
    image() {
      return this.$store.state.productData.dimensions.min_max_values.image;
    },
    selectableFrameExtensionsAbove() {
      return {
        total:
          this.$store.state.productData.model?.frame_extension_above?.items
            ?.length,
        items:
          this.$store.state.productData.model?.frame_extension_above?.items,
      };
    },
    frame_extension_above: {
      get() {
        return this.customerConfig.frame_extension_above;
      },
      async set(value) {
        let new_set = (this.customerConfig.frame_extension_above = value);
        await this.$store.dispatch(
          "configuration/setPriceFromApi",
          {},
          { root: true }
        );
        return new_set;
      },
    },
    selectableFrameExtensionsBelow() {
      return {
        total:
          this.$store.state.productData.model?.frame_extension_below?.items
            ?.length,
        items:
          this.$store.state.productData.model?.frame_extension_below?.items,
      };
    },
    frame_extension_below: {
      get() {
        return this.customerConfig.frame_extension_below;
      },
      async set(value) {
        let new_set = (this.customerConfig.frame_extension_below = value);
        await this.$store.dispatch(
          "configuration/setPriceFromApi",
          {},
          { root: true }
        );
        return new_set;
      },
    },
    selectableFrameExtensionsLeft() {
      return {
        total:
          this.$store.state.productData.model?.frame_extension_left?.items
            ?.length,
        items: this.$store.state.productData.model?.frame_extension_left?.items,
      };
    },
    frame_extension_left: {
      get() {
        return this.customerConfig.frame_extension_left;
      },
      async set(value) {
        let new_set = (this.customerConfig.frame_extension_left = value);
        await this.$store.dispatch(
          "configuration/setPriceFromApi",
          {},
          { root: true }
        );
        return new_set;
      },
    },
    selectableFrameExtensionsRight() {
      return {
        total:
          this.$store.state.productData.model?.frame_extension_right?.items
            ?.length,
        items:
          this.$store.state.productData.model?.frame_extension_right?.items,
      };
    },
    frame_extension_right: {
      get() {
        return this.customerConfig.frame_extension_right;
      },
      async set(value) {
        let new_set = (this.customerConfig.frame_extension_right = value);
        await this.$store.dispatch(
          "configuration/setPriceFromApi",
          {},
          { root: true }
        );
        return new_set;
      },
    },
    customerConfig() {
      return this.$store.state.configuration.customerConfig;
    },
    productData() {
      return this.$store.state.productData.model;
    },
    selectableItems() {
      return {
        total: this?.item?.items?.length,
        items: this.showAll
          ? this?.item?.items
          : this?.item?.items?.slice(0, this.maxItems),
      };
    },
    groupedItems() {
      return this.groupArrayBy(
        this?.selectableItems?.items,
        (item) => item?.group_name
      );
    },
    isOptional() {
      return this.$store.state.productData.model?.[this.itemType]?.isOptional;
    },
  },
  methods: {
    async toggleOptionalConfig(value) {
      this.optionalConfigActive = value;
      // default
      const payload = {
        itemType: this.itemType,
        value: value,
      };
      await this.$store.dispatch(
        "productData/toggleOptionalSelection",
        payload
      );
      // above
      const payload_above = {
        itemType: "frame_extension_above",
        value: value,
      };
      await this.$store.dispatch(
        "productData/toggleOptionalSelection",
        payload_above
      );
      // below
      const payload_below = {
        itemType: "frame_extension_below",
        value: value,
      };
      await this.$store.dispatch(
        "productData/toggleOptionalSelection",
        payload_below
      );
      // left
      const payload_left = {
        itemType: "frame_extension_left",
        value: value,
      };
      await this.$store.dispatch(
        "productData/toggleOptionalSelection",
        payload_left
      );
      // right
      const payload_right = {
        itemType: "frame_extension_right",
        value: value,
      };
      await this.$store.dispatch(
        "productData/toggleOptionalSelection",
        payload_right
      );
      await this.$store.dispatch(
        "configuration/setPriceFromApi",
        {},
        { root: true }
      );
    },
  },
  watch: {
    "item.items": {
      handler() {
        this.showAll = false;
      },
    },
    showAll: {
      handler() {
        const payload = {
          itemType: this.itemType,
          maxItems: this.maxItems,
        };
        this.$store.dispatch("productData/moveItemToStayVisible", payload);
      },
    },
  },
};
</script>
<style scoped>
/deep/ .theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn.active-btn {
  border: 2px solid #F59100 !important;
  background-color: white !important;
  border-radius: 4px !important;
}

.inactive-btn {

}

.letwork-card-icon {
  position: absolute !important;
  right: -20px;
  top: -23px;
  border-radius: 2px;
  padding: 2px;
  background-color: #F59100;
  font-size: 25px;
  color: white !important;
  z-index: 2;
}

.item-row {
  margin-bottom: 2rem;
}
</style>
