import axios from "axios";
//import BackendService from "@/services/BackendService";

export const namespaced = true;
import Vue from "vue";

import {i18n} from "@/plugins/i18n";
import BackendService from "@/services/BackendService";

// eslint-disable-next-line no-unused-vars
const vat = 0.19;
const defaultPostWidth = 60;

export const state = {
    customerConfig: {
        thumbnail: null,
        materials: null,
        series: null,
        profiles: null,
        profile_designs: null,
        divisions: null,
        openings: null,
        uuid: '',
        dimensions: {
            splitting: [],
            handle_heights: [],
            total_width: null,
            total_height: null,
            segments: [],
        },
        decor_inside: null,
        decor_outside: null,
        glazing: null,
        finalConfig: null,
        finalConfigJson: null,
        mainPrice: {
            value: 0,
            vat: 0,
        },
        discount: {
            date: '',
            oldPrice: 0,
            percent: 0,
            name: '',
        },
        PriceCalculations: {
            purchasing_price_1: "",
            purchasing_price_2: "",
            purchasing_price_3: "",
        },
    },
    // used to reset the user config
    notResettableSections: [
        "materials",
        "series",
        "profiles",
        "mainPrice",
        "discount",
        "PriceCalculations",
        "dimensions",
        "thumbnail",
        "glazing_bars_divisions",
        "finalConfig",
        "finalConfigJson",
        "uuid",
    ],
};

const distributeEvenly = function (total, divider) {
    let groups = [];
    for (let member of distributeInteger(total, divider)) {
        groups.push(member);
    }
    return groups;
};

const distributeInteger = function* (total, divider) {
    if (divider === 0) {
        return 0;
    } else {
        let rest = total % divider;
        let result = total / divider;

        for (let i = 0; i < divider; i++) {
            if (rest-- > 0) {
                yield Math.ceil(result);
            } else {
                yield Math.floor(result);
            }
        }
    }
};

export const mutations = {
    DELETE_UUID(state) {
        Vue.set(state.customerConfig, 'uuid', '');
    },
    SET_CONFIG_DATA(state, payload) {
        // state.customerConfig[payload.caller] = payload.selection;
        Vue.set(state.customerConfig, payload.caller, payload.selection);
    },
    SET_CONFIGURATION_TEMP(state, payload) {
        Vue.set(this.state.productData, "customerConfigTemp", payload);
    },
    SET_SETUP_TEMP(state, payload) {
        payload.modals = null;
        Vue.set(this.state.productData, "setupTemp", payload);
    },
    SET_CONFIGURATION_FROM_EXIST_PRODUCT(state, payload) {
        const appElement = document.getElementById('app');

        appElement.setAttribute('data-load_material_id', payload.materials.id);
        appElement.setAttribute('data-load_series_id', payload.series.id);
        appElement.setAttribute('data-load_product_id', payload.profiles.id);

        Vue.set(this.state.productData.materials, "id", payload.materials.id);
        Vue.set(this.state.productData.series, "id", payload.series.id);
        Vue.set(this.state.productData.profiles, "id", payload.profiles.id);
        Vue.set(this.state.productData, "customerConfigTemp", payload);

        setTimeout(() => {
            Vue.set(state, "customerConfig", payload);
        }, "1800");
    },
    SET_SETUP_FROM_EXIST_PRODUCT(state, payload) {
        const appElement = document.getElementById('app');

        appElement.setAttribute('data-load_material_id', payload.loadMaterialId);
        appElement.setAttribute('data-load_series_id', payload.loadSeriesId);
        appElement.setAttribute('data-load_product_id', payload.loadProductId);
        appElement.setAttribute('data-reload_product', "1");
        appElement.setAttribute('data-reload_product_id', this.state.productData.profiles.id);
        appElement.setAttribute('data-reload_product_division_id', payload.reloadDivisionId);
        appElement.setAttribute('data-reload_product_opening_id', payload.reloadProductOpeningId);
        appElement.setAttribute('data-reload_product_opening_tab', payload.reloadProductOpeningTab);
        appElement.setAttribute('data-load_division_id', payload.loadDivisionId);
        appElement.setAttribute('data-load_product_type', payload.loadProductType);
    },
    SET_DIMENSIONS_DATA(state, payload) {
        state.customerConfig.dimensions[payload.key] = Number(payload.value);
    },
    SET_SPLITTING_DIMENSIONS_DATA(state, payload) {
        if (payload.key === "total_width") {
            state.customerConfig.dimensions.splitting[0].total_width = Number(payload.value);
            state.customerConfig.dimensions.splitting[0].section_widths[0] = Number(payload.value);
            state.customerConfig.dimensions.splitting[0].validated_widths[0] = Number(payload.value);
        }
        if (payload.key === "total_height") {
            state.customerConfig.dimensions.splitting[0].height = Number(payload.value);
        }
    },
    SET_SPLITTING_FROM_API(state, payload) {
        //payload[0].validated_widths = Object.entries(payload[0].validated_widths)
        Vue.set(state.customerConfig.dimensions, "splitting", payload);
        //state.customerConfig.dimensions.splitting[0] = payload;
    },
    SET_CSS_LOADING_WOOCOMMERCE() {
        const element = document.getElementsByClassName("configurator__add-to-cart")[0];
        if (element) {
            element.classList.add("loading");
        }
    },
    REMOVE_CSS_LOADING_WOOCOMMERCE() {
        const element = document.getElementsByClassName("configurator__add-to-cart")[0];
        if (element) {
            element.classList.remove("loading");
        }
    },
    SET_PRICING_WOOCOMMERCE(state) {
        const element = document.getElementsByClassName("configurator__add-to-cart")[0];
        if (element) {
            let outputPriceButtonWoo = '<span>' + i18n.n(state.customerConfig.mainPrice.value, "currency") + '</span>';
            let oldPrice = state.customerConfig.discount.oldPrice ?? 0;
            if (oldPrice > 0) {
                outputPriceButtonWoo = '<span style="text-decoration: line-through;">' + i18n.n(state.customerConfig.discount.oldPrice, "currency") + '&nbsp;&nbsp;' + '</span> &nbsp;&nbsp;' + i18n.n(state.customerConfig.mainPrice.value, "currency");
            }
            element.innerHTML = outputPriceButtonWoo;
        }
    },
    SET_PRICE_FROM_API(state, payload) {
        Vue.set(state.customerConfig.mainPrice, "value", payload);
    },
    SET_DISCOUNT_FROM_API(state, payload) {
        Vue.set(state.customerConfig, "discount", payload);
    },
    SET_PRICE_CALCULATIONS_1_FROM_API(state, payload) {
        Vue.set(state.customerConfig.PriceCalculations, "purchasing_price_1", payload);
    },
    SET_PRICE_CALCULATIONS_2_FROM_API(state, payload) {
        Vue.set(state.customerConfig.PriceCalculations, "purchasing_price_2", payload);
    },
    SET_PRICE_CALCULATIONS_3_FROM_API(state, payload) {
        Vue.set(state.customerConfig.PriceCalculations, "purchasing_price_3", payload);
    },
    SET_UUID_FROM_API(state, payload) {
        Vue.set(state.customerConfig, "uuid", payload);
    },
    SET_FINAL_CONFIG_FROM_API(state, payload) {
        Vue.set(state.customerConfig, "finalConfig", payload.description);
        Vue.set(state.customerConfig, "finalConfigJson", payload.description_json);
    },
    SET_SPLITTING(state) {
        // create a (2d) array from the opening code
        const decodedOpeningCode = state.customerConfig.openings.opening_code
            ?.split("_")
            .map((c) => c.split("-"));

        const rows = decodedOpeningCode?.length;
        let heights;
        if (rows > 1) {
            // round the top row to the nearest 5
            const upperSegment =
                Math.ceil(state.customerConfig.dimensions.total_height / 15) * 5;
            heights = [
                upperSegment,
                state.customerConfig.dimensions.total_height - upperSegment,
            ];
        } else {
            heights = [state.customerConfig.dimensions.total_height];
        }

        const splitting = [];

        decodedOpeningCode.forEach((row, index) => {
            splitting.push({
                height: heights[index],
                validated_height: heights[index],
                total_width: state.customerConfig.dimensions.total_width,
                section_widths: distributeEvenly(
                    state.customerConfig.dimensions.total_width,
                    decodedOpeningCode[index].length
                ),
                validated_widths: distributeEvenly(
                    state.customerConfig.dimensions.total_width,
                    decodedOpeningCode[index].length
                ),
                custom_handle_position_height: false,
                handle_position_height: undefined,
                section_codes: decodedOpeningCode[index],
                v_posts: Array(decodedOpeningCode[index].length - 1).fill(
                    defaultPostWidth
                ),
                h_posts: decodedOpeningCode.length > 1 ? defaultPostWidth : 0,
            });
        });

        // console.log("Splitting: ", splitting);
        Vue.set(state.customerConfig.dimensions, "splitting", splitting);

        // clean the splitting
        // state.customerConfig.dimensions.splitting.columns.splice(0);
        // state.customerConfig.dimensions.splitting.rows.splice(0);
        // state.customerConfig.dimensions.splitting.h_posts.splice(0);
        // state.customerConfig.dimensions.splitting.v_posts.splice(0);

        // build the object from our opening code array by looping through it
        // and create a new key for each row and then a new property for each column
        // within each row(-key)
        // decodedOpeningCode.map((c, i) => {
        //   state.customerConfig.dimensions.splitting.rows.push(heights[i]);
        //   c.map((c, i) => {
        //     state.customerConfig.dimensions.splitting.columns.push(widths[i]);
        //   });
        // });
        //
        // if (columns > 1) {
        //   for (
        //     let i = 1;
        //     i < state.customerConfig.dimensions.splitting.columns.length;
        //     i++
        //   ) {
        //     state.customerConfig.dimensions.splitting.h_posts.push(
        //       defaultPostWidth
        //     );
        //   }
        // }
        // if (rows > 1) {
        //   for (
        //     let i = 1;
        //     i < state.customerConfig.dimensions.splitting.rows.length;
        //     i++
        //   ) {
        //     state.customerConfig.dimensions.splitting.v_posts.push(
        //       defaultPostWidth
        //     );
        //   }
        // }
    },
    ENABLE_CONFIG_FAVORITES(state, payload) {
        // we want to determine the auto selected (favorite) items
        // so we iterate through the categories data
        for (const [key, value] of Object.entries(payload)) {
            // if (value.optionalSelected === false) {
            //   state.customerConfig[key] = undefined;
            // }
            if (!value?.items) continue;
            // find the favorite item and set the initial config
            let v, glazing_bars_divisions;

            // check if this is an optional category
            if (value.isOptional) {
                // if it is, was it enabled by the customer?
                if (value.optionalSelected && !value.multipleChoice) {
                    // if yes, enable the standard item
                    v = Object.values(value.items).find((i) => i.id === value.standardItem);

                    // are we dealing with glazing bars? if yes, we need to pre-fill
                    // the glazing bars divisions with an empty array
                    if (key === "glazing_bars") {
                        glazing_bars_divisions = [];
                    }
                    // if not = the user deactivated that category,
                    // remove the config
                } else {
                    v = undefined;

                    // are we dealing with glazing bars? if yes, we need to unset
                    // the glazing bars divisions as well
                    if (key === "glazing_bars") {
                        glazing_bars_divisions = undefined;
                    }
                }

                // if it isnt an optional category, enable the standard item
            } else {
                v = Object.values(value.items).find((i) => i.id === value.standardItem);

                // set division by loadDivisionId
                if (this.state.setup.loadDivisionId && this.state.setup.loadDivisionId !== "undefined" && key === "divisions") {
                    let loadDivisionId = parseInt(this.state.setup.loadDivisionId);
                    let obj = value.items;
                    for (let key in obj) {
                        if (obj[key].id && obj[key].id === loadDivisionId) {
                            v = obj[key];
                        }
                    }
                }

                // set opening by loadProductType
                if (this.state.setup.loadProductType && this.state.setup.loadProductType !== "undefined" && key === "openings") {
                    //console.log(this.state.setup.loadProductType)
                    let parentIconId = this.state.configuration.customerConfig.divisions.id;
                    let loadProductType = this.state.setup.loadProductType;
                    let obj = value.items;
                    for (let key in obj) {
                        if (obj[key].group_type_number_for_favorite_item && obj[key].group_type_number_for_favorite_item === loadProductType && obj[key].parent_icon_id === parentIconId) {
                            v = obj[key];
                        }
                    }
                }

                // reload division by reloadDivisionId
                if (this.state.setup.reloadDivisionId && this.state.setup.reloadDivisionId !== "undefined" && key === "divisions") {
                    let reloadDivisionId = parseInt(this.state.setup.reloadDivisionId);
                    let obj = value.items;
                    for (let key in obj) {
                        if (obj[key].id && obj[key].id === reloadDivisionId) {
                            v = obj[key];
                        }
                    }
                }

                // reload opening by reloadProductType
                if (this.state.setup.reloadProductOpeningId && this.state.setup.reloadProductOpeningId !== "undefined" && key === "openings") {
                    let obj = value.items;
                    let reloadProductOpeningId = parseInt(this.state.setup.reloadProductOpeningId);
                    for (let key in obj) {
                        if (obj[key].id === reloadProductOpeningId) {
                            v = obj[key];
                        }
                    }
                }

                // set division by quickDivision
                if (this.state.setup.quickDivision && this.state.setup.quickDivision !== "undefined" && key === "divisions") {
                    let loadDivisionId = parseInt(this.state.setup.quickDivision);
                    let obj = value.items;
                    for (let key in obj) {
                        if (obj[key].id && obj[key].id === loadDivisionId) {
                            v = obj[key];
                        }
                    }
                }

                // set opening by quickOpening
                if (this.state.setup.quickOpening && this.state.setup.quickOpening !== "undefined" && key === "openings") {
                    let obj = value.items;
                    let reloadProductOpeningId = parseInt(this.state.setup.quickOpening);
                    for (let key in obj) {
                        if (obj[key].id === reloadProductOpeningId) {
                            v = obj[key];
                        }
                    }
                }

                // set security plus item by quickAddons
                if (this.state.setup.quickAddons && this.state.setup.quickAddons.indexOf('security_plus') !== -1 && key === "security_packages") {
                    let obj = value.items;
                    for (let key in obj) {
                        if (obj[key].is_security_plus_item === 1) {
                            v = obj[key];
                        }
                    }
                }

                // set security plus item by quickAddons
                if (this.state.setup.quickAddons && this.state.setup.quickAddons.indexOf('isolation_plus') !== -1 && key === "glazing") {
                    let obj = value.items;
                    for (let key in obj) {
                        if (obj[key].is_isolation_plus_item === 1) {
                            v = obj[key];
                        }
                    }
                }

            }

            Vue.set(state.customerConfig, key, v);

            if (key === "openings") {
                const minMax = {
                    min_height: Number(v?.min_height),
                    max_height: Number(v?.max_height),
                    min_width: Number(v?.min_width),
                    max_width: Number(v?.max_width),
                    default_width: Number(v?.default_width),
                    default_height: Number(v?.default_height),
                    opening_code: v?.opening_code,
                    image: v?.image,
                };
                Vue.set(this.state.productData.dimensions, "min_max_values", minMax);
            }

            if (key === "glazing_bars") {
                Vue.set(state.customerConfig,"glazing_bars_divisions",glazing_bars_divisions);
            }
        }
    },
    // eslint-disable-next-line no-unused-vars
    PREFILL_DIMENSIONS_DATA(state, payload) {
        // set dimensions to the default width and height
        state.customerConfig.dimensions.total_width =
            state.customerConfig.openings.default_width;
        state.customerConfig.dimensions.total_height =
            state.customerConfig.openings.default_height;
    },
    FIX_CUSTOMER_CONFIG(state, modelData) {
        // determine all model attribute names
        const configSectionsToCheck = Object.keys(state.customerConfig).filter(
            (c) => !state.notResettableSections.includes(c)
        );

        // reset selection to first item if the
        // item.id does not exist in that category
        configSectionsToCheck.forEach((c) => {
            const validSectionIds = modelData[c]?.items?.map((i) => i?.id);
            const currentSectionConfigId = state.customerConfig[c]?.id;
            const defaultItem = modelData[c]?.items?.find((i) => i.favorite === 1);

            if (!validSectionIds.includes(currentSectionConfigId)) {
                let value;
                // check if this is an optional category
                if (modelData[c].isOptional) {
                    // if it is, was it enabled by the customer?
                    if (modelData[c].optionalSelected) {
                        // if yes, enable the standard item
                        // if the current selection is invalid, set the config
                        // to either the default or if it not exists, set it
                        // to the first item of the section
                        value = validSectionIds.includes(defaultItem?.id)
                            ? defaultItem
                            : modelData[c]?.items?.[0];
                    } else if (validSectionIds.length === 0) {
                        // set to null if there is no valid selection possible
                        value = null;
                    }
                } else {
                    value = validSectionIds.includes(defaultItem?.id)
                        ? defaultItem
                        : modelData[c]?.items?.[0];
                }

                if (validSectionIds.length === 0) {
                    // set to null if there is no valid selection possible
                    value = null;
                }
                Vue.set(state.customerConfig, c, value);
            }
        });
    },
};
export const actions = {
    deleteUuid({commit}) {
        commit("DELETE_UUID");
    },
    setConfigData({commit}, payload) {
        commit("SET_CONFIG_DATA", payload);
    },
    enableFavorites({commit}, payload) {
        commit("ENABLE_CONFIG_FAVORITES", payload);
    },
    fixCustomerConfig({commit, dispatch}, payload) {
        commit("FIX_CUSTOMER_CONFIG", payload.model);
        dispatch("prefillDimensionsData", payload);
    },
    prefillDimensionsData({commit}, payload) {
        // do not reset dimensions when later categories (e.g. a decor) changes
        if (payload.caller === "openings" || payload.caller === undefined) {
            commit("PREFILL_DIMENSIONS_DATA", payload);
            commit("SET_SPLITTING");
        }
    },
    setDimensionsData({commit}, payload) {
        commit("SET_DIMENSIONS_DATA", payload);
        commit("SET_SPLITTING");
    },
    setDimensionsDataQuick({commit}, payload) {
        commit("SET_DIMENSIONS_DATA", payload);
    },
    resetDimensionsSplittingData({commit}) {
        commit("SET_SPLITTING");
    },
    resetDimensionsData({commit}, payload) {
        commit("SET_DIMENSIONS_DATA", payload);
        commit("SET_SPLITTING");
    },
    setSplittingDataFromApi({commit}, payload) {
        commit("SET_SPLITTING_FROM_API", payload);
    },
    async check_decor(payload) {
        const customerConfig = {
            config: this.state.configuration.customerConfig,
            setup: this.state.setup,
            item: payload,
        };
        //console.log(customerConfig);
        //const response = await BackendService.getFinalPrice(this.state.configuration.customerConfig);
        let response = await axios.post(
            process.env.VUE_APP_API_URL + `check-decor`,
            customerConfig,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Authorization": process.env.VUE_APP_API_APP_KEY,
                }
            }
        );
        console.log(response.data);
        //await commit("SET_PRICE_FROM_API", response.data.p_price);
    },
    async setPriceFromApi({commit}) {
        const customerConfig = {
            config: this.state.configuration.customerConfig,
            setup: this.state.setup,
        };
        //console.log(customerConfig);
        //const response = await BackendService.getFinalPrice(this.state.configuration.customerConfig);
        let response = await axios.post(
            process.env.VUE_APP_API_URL + `get-price`,
            customerConfig,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Authorization": process.env.VUE_APP_API_APP_KEY,
                }
            }
        );
        await commit("SET_CSS_LOADING_WOOCOMMERCE");
        await commit("SET_PRICE_FROM_API", response.data.p_price);
        await commit("SET_DISCOUNT_FROM_API", response.data.p_discount_data);
        await commit("SET_PRICING_WOOCOMMERCE", this.state.configuration);
        await commit("SET_PRICE_CALCULATIONS_1_FROM_API", response.data.p_purchasing_price_1);
        await commit("SET_PRICE_CALCULATIONS_2_FROM_API", response.data.p_purchasing_price_2);
        await commit("SET_PRICE_CALCULATIONS_3_FROM_API", response.data.p_purchasing_price_3);
        await commit("REMOVE_CSS_LOADING_WOOCOMMERCE");

        if (this.state.setup.cartOlymp === '1'){
            await commit("SET_PRICE_FROM_API", response.data.p_price_without_discount);
        }

    },
    async getFinalConfigFromApi({commit, rootGetters}) {
        const customerConfig = {
            config: this.state.configuration.customerConfig,
            setup: this.state.setup,
            productData: this.state.productData,
            finalCall: 1,
            languageId: this.state.setup.apiLanguageId,
        };
        let response = await axios.post(
            process.env.VUE_APP_API_URL + `get-price`,
            customerConfig,
            {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    "Authorization": process.env.VUE_APP_API_APP_KEY,
                }
            }
        );
        await commit("SET_CSS_LOADING_WOOCOMMERCE");
        await commit("SET_PRICE_FROM_API", response.data.p_price);
        await commit("SET_DISCOUNT_FROM_API", response.data.p_discount_data);
        await commit("SET_PRICING_WOOCOMMERCE", this.state.configuration);
        await commit("SET_UUID_FROM_API", response.data.uuid);
        await commit("SET_PRICE_CALCULATIONS_1_FROM_API", response.data.p_purchasing_price_1);
        await commit("SET_PRICE_CALCULATIONS_2_FROM_API", response.data.p_purchasing_price_2);
        await commit("SET_PRICE_CALCULATIONS_3_FROM_API", response.data.p_purchasing_price_3);
        await commit("SET_FINAL_CONFIG_FROM_API", response.data.p_final_config);
        await commit("REMOVE_CSS_LOADING_WOOCOMMERCE");

        if (this.state.setup.cartOlymp === '1'){
            await commit("SET_PRICE_FROM_API", response.data.p_price_without_discount);
        }

        let SVGDomElement = btoa(
            document.getElementById("window_configuration").innerHTML
        );
        document.getElementById("js-config-svg").value = SVGDomElement;

        // uuid
        document.getElementById("js-uuid").value = response.data.uuid;
        document.getElementById("js-additional-products").value = response.data.additional_products;

        if (response.data.p_final_config.active == true && document.getElementById("js-config-svg").value != "") {
            if (rootGetters["setup/getIframeMode"] && rootGetters["setup/getIframeMode"] == 1) {
                response.data.svg_image = document.getElementById("js-config-svg").value;
                window.parent.postMessage(response.data, "*");
            } else {
                document.getElementById("form_add_cart").submit();
            }
        }
    },
    async setConfigurationFromExistProduct({commit}) {
        await commit("SET_CSS_LOADING_WOOCOMMERCE");
        // let payload_configuration = JSON.parse(this.state.setup.configuratorJson);
        // await commit(
        //     "SET_CONFIGURATION_FROM_EXIST_PRODUCT",
        //     payload_configuration.customerConfig
        // );
        await commit("SET_PRICING_WOOCOMMERCE", this.state.configuration);
        await commit("REMOVE_CSS_LOADING_WOOCOMMERCE");
    },
    async setConfigurationFromUuid({ commit }) {
        try {
            const response = await BackendService.getDataByUuid();
            //const payload_setup_uuid = JSON.parse(response.data.json_2);
            //const payload_configuration_uuid = JSON.parse(response.data.json_1);

            await commit("SET_SETUP_FROM_EXIST_PRODUCT", JSON.parse(response.data.json_2));
            await commit("SET_CONFIGURATION_FROM_EXIST_PRODUCT", JSON.parse(response.data.json_1))
            await commit("SET_CONFIGURATION_TEMP", JSON.parse(response.data.json_1))
            await commit("SET_SETUP_TEMP", JSON.parse(response.data.json_2))
            //console.log(JSON.parse(response.data.json_1))
            // Rückgabe der finalen Promise
            return response.data;
        } catch (e) {
            console.error("Fehler beim Setzen der Konfiguration:", e);
            throw e; // Optional: Fehler weiterwerfen, um sie an anderer Stelle zu behandeln
        }
    },
    // async setConfigurationFromUuid({commit}) {
    //     try {
    //         const response = await BackendService.getDataByUuid();
    //         //const payload_configuration_uuid = JSON.parse(response.data.json_1);
    //         const payload_setup_uuid = JSON.parse(response.data.json_2);
    //         //await commit("SET_CONFIGURATION_FROM_EXIST_PRODUCT", payload_configuration_uuid)
    //         await commit("SET_SETUP_FROM_EXIST_PRODUCT", payload_setup_uuid)
    //     } catch (e) {
    //         console.log(e);
    //     }
    // }
};
