import { i18n } from "@/plugins/i18n";
import {mapActions} from "vuex";

export const mainMixin = {
  // data() {
  //return {
  //notEmptyRule: [(v) => !!v || i18n.n("currency")],
  //};
  //},
  data: () => ({
    overlay: false,
    cursorStyle: {
      cursor: "pointer",
    },
  }),
  methods: {
    ...mapActions({
      openModal: 'setup/openModal'
    }),
    hasValidUuid() {
      const uuid = this.$store.state.configuration.customerConfig.uuid;
      return uuid && typeof uuid === 'string' && uuid.length > 10;
    },
    scrollToAnchor(anchorId) {
      const anchorElement = document.getElementById(anchorId);
      if (anchorElement) {
        anchorElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    changeCursorStyle() {
      this.cursorStyle.cursor = "pointer";
    },
    resetCursorStyle() {
      this.cursorStyle.cursor = "auto";
    },
    // capitalize the first character of a string
    capitalizeString(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
    },

    // convert a string to camelCase
    camelize(str) {
      return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
    },

    // get the loading method name for the next in line
    getNextMethodName(elementType) {
      return `get${this.capitalizeString(
        this.getNextElementType(elementType)
      )}`;
    },

    // build the price string for an item card based
    // on the calculation type of a section item
    itemPriceString(item) {
      if (item.price === 0 || this.$store.state.setup.cartOlymp === '1') return;
      let priceString = "";
      switch (item.calculation_type) {
        case undefined:
          priceString = i18n.t("pricing.price", {
            price: i18n.n(item.price, "currency"),
          });
          break;
        case "prozent":
          priceString = i18n.t("pricing.percent", {
            price: item.price,
          });
          break;
        case "m2":
          priceString = i18n.t("pricing.perSquareMeter", {
            price: i18n.n(item.price, "currency"),
          });
          break;
        case "lfmh":
          priceString = i18n.t("pricing.perMeter", {
            price: i18n.n(item.price, "currency"),
          });
          break;
        case "lfmv":
          priceString = i18n.t("pricing.perMeter", {
            price: i18n.n(item.price, "currency"),
          });
          break;
        case "stk":
          priceString = i18n.t("pricing.perPiece", {
            price: i18n.n(item.price, "currency"),
          });
          if (item.glazing_bar_width) {
            priceString = i18n.t("pricing.perField", {
              price: i18n.n(item.price, "currency"),
            });
          }
          break;
      }
      return priceString;
    },

    // checks if an object is empty
    isEmpty(obj) {
      return Object.keys(obj).length === 0;
    },

    // build the image url for an item card picture
    imageURL(image) {
      let imageUrl;
      if (image) {
        imageUrl = `${process.env.VUE_APP_API_IMAGE_URL}/${image}`;
      } else {
        // imageUrl = "http://placehold.jp/24/cc9999/993333/150x100.png";
        imageUrl = undefined;
      }
      return imageUrl;
    },
    imageOpeningURL(image) {
      let imageUrl;
      if (image) {
        imageUrl = `${process.env.VUE_APP_API_IMAGE_OPENINGS_URL}/${image}`;
      } else {
        imageUrl = undefined;
      }
      return imageUrl;
    },
    imageNativeURL(image) {
      let imageUrl;
      if (image) {
        imageUrl = `${process.env.VUE_APP_API_IMAGE_NATIVE_URL}/${image}`;
      } else {
        imageUrl = undefined;
      }
      return imageUrl;
    },
    // calculate the grid for the item cards
    columnWidth(columns) {
      return Math.round(12 / columns);
    },

    // groups an array of objects by a given key
    groupArrayBy(list, keyGetter) {
      if (list) {
        const map = new Map();
        list.forEach((item) => {
          const key = keyGetter(item);
          const collection = map.get(key);
          if (!collection) {
            map.set(key, [item]);
          } else {
            collection.push(item);
          }
        });
        return Object.fromEntries(map);
      }
    },
    distributeEvenly(total, divider) {
      let groups = [];
      for (let member of this.distributeInteger(total, divider)) {
        groups.push(member);
      }
      return groups;
    },
    distributeInteger(total, divider) {
      if (divider === 0) {
        return 0;
      } else {
        let rest = total % divider;
        let result = total / divider;

        for (let i = 0; i < divider; i++) {
          if (rest-- > 0) {
            return Math.ceil(result);
          } else {
            return Math.floor(result);
          }
        }
      }
    },
    countElements(array, value) {
      let count = 0;
      for (let i = 0; i < array.length; ++i) {
        if (array[i] == value) count++;
      }
      return count;
    },
    countAutoSelectItems(array) {
      let count = 0;
      for (let i = 0; i < array.length; ++i) {
        count++;
      }
      return count;
    },
    isHideDeactivatedItems(category) {
      const categoryItems = this.$store.state.productData.model[category]?.items;
      const hideCategoryItems = this.$store.state.productData.model[category]?.hideDeactivatedItems;
      if (categoryItems && categoryItems.length > 0) {
        let filtered = categoryItems.filter(item => !this.isItemDeactivated(item));
        if (filtered.length < 1 && hideCategoryItems === 1){
          return false;
        }
      }
      return true;
    },
    isItemDeactivated(item) {
      let deactivated = false;
      if (this.countPrividedItems(item.provided_items) > 0) {
        deactivated = this.isSetPrividedItem(item.provided_items);
      }
      if (this.countConflictItems(item.conflict_items) > 0) {
        deactivated = this.isSetConflictItem(item.conflict_items);
      }
      return deactivated;
    },
    countConflictItems(array) {
      let count = 0;
      for (let i = 0; i < array.length; ++i) {
        count++;
      }
      return count;
    },
    isSetConflictItem(array) {
      let deactivated = false;
      for (let i = 0; i < array.length; ++i) {
        let configuration = this.$store.state.configuration.customerConfig;

        Object.keys(configuration).forEach((key) => {
          if (configuration[key]?.id !== "undefined") {
            if (configuration[key]?.id === array[i]) {
              deactivated = true;
            }
          }
        });
      }
      return deactivated;
    },
    countPrividedItems(array) {
      let count = 0;
      for (let i = 0; i < array.length; ++i) {
        count++;
      }
      return count;
    },
    isSetPrividedItem(array) {
      let deactivated = true;
      for (let i = 0; i < array.length; ++i) {
        let configuration = this.$store.state.configuration.customerConfig;

        Object.keys(configuration).forEach((key) => {
          if (configuration[key]?.id !== "undefined") {
            if (configuration[key]?.id === array[i]) {
              deactivated = false;
            }
          }
        });
      }
      return deactivated;
    },
    decodeOpeningCode(openingCode) {
      return openingCode.split("_").map((c) => c.split("-"));
    },
    decorUrl() {
      return this.imageURL(this.customerConfig.decor_outside?.image);
    },
    debug(stuff) {
      console.log(stuff);
    },
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    apiLoading() {
      return this.$store.state.productData.loading;
    },
    customerConfig() {
      return this.$store.state.configuration.customerConfig;
    },
  },
};
