<template>
  <v-card
    @mouseover="onMouseOver"
    @mouseleave="onMouseLeave"
    :disabled="active"
    outlined
    class="d-flex flex-column letwork-card"
    height="100%"
    transition="scale-transition"
    :class="{ 'selected-card': selected }"
  >
    <div v-if="recommended !== ''" class="recommended-badge">
      {{ recommended }}
    </div>

    <v-icon v-show="hovered" class="magnify-icon" :class="{'magnify-icon-if-recommended': recommended !== ''}" @click="openModal">mdi-magnify</v-icon>

    <v-card-title
      @click="toggleItem(item)"
      @mouseover="changeCursorStyle"
      @mouseleave="resetCursorStyle"
      :style="cursorStyle"
      :id="setIdByAutomaticLoadingItem"
      class="letwork-card-titel"
    >
      <v-icon v-if="selected" class="letwork-card-icon">mdi-check</v-icon>
      <v-img :src="imageURL(item.image)" contain :max-height="img_max_height" v-if="this.itemType !== 'openings'" class="letwork-card-image"></v-img>
      <v-img :src="imageOpeningURL(item.opening_code + '.png')" contain :max-height="img_max_height" v-if="this.itemType === 'openings'" class="letwork-card-image"></v-img>

    </v-card-title>

    <!-- Modal -->
    <v-dialog v-model="dialog" max-width="600px">
      <v-card @click="dialog = false">
        <!-- Schließen-Button rechts oben -->
        <v-btn icon @click="dialog = false" class="close-btn">
          <v-icon color="red">mdi-close</v-icon>
        </v-btn>

        <!-- Titel des Modals -->
        <v-card-title v-html="item.label" style="padding-right: 35px; font-size: 16px;"></v-card-title>

        <!-- Bild im Modal -->
        <v-card-text>
          <v-img :src="imageURL(item.image)" max-height="400px" contain></v-img>
        </v-card-text>
        <v-card-text v-html="item.description_long"></v-card-text>
      </v-card>
    </v-dialog>

    <v-card-text class="flex-grow-1 letwork-card-text">
      <div
        v-text="item.label"
        class="text-center font-weight-light letwork-titel"
        @click="toggleItem(item)"
        @mouseover="changeCursorStyle"
        @mouseleave="resetCursorStyle"
        :style="cursorStyle"
      ></div>
      <v-divider v-if="item.description_short" class="my-4"></v-divider>
      <div v-if="!expanded && item.description_short">
        <div v-if="shouldShowReadMore">
          <div
            v-html="limitedText"
            class="letwork-text"
            @click="toggleItem(item)"
            @mouseover="changeCursorStyle"
            @mouseleave="resetCursorStyle"
            :style="cursorStyle"
          ></div>
          <v-btn @click="expanded = true" text small width="100%" class="letwork-card-text-read-more-button">{{ $t("defaultTexts.readMore") }}</v-btn>
        </div>
        <div v-else>
          <div
            v-html="fullText"
            class="letwork-text"
            @click="toggleItem(item)"
          ></div>
        </div>
      </div>
      <div v-else>
        <div
          v-html="fullText"
          class="letwork-text"
          @click="toggleItem(item)"
          @mouseover="changeCursorStyle"
          @mouseleave="resetCursorStyle"
          :style="cursorStyle"
        ></div>
      </div>
      <!--<div v-html="item.description_short" style="font-size: 0.9rem"></div>-->
      <div v-if="active" style="text-align: center; color: darkred">
        <small>{{ $t("defaultTexts.notSelectable") }}</small>
      </div>
      <div v-if="enquiryButton !== ''" style="text-align: center; color: darkred" @click="toggleItem(item)">
        <v-btn class="mx-auto letwork-enquiry-button" v-text="$t('optional.enquiry')"></v-btn>
      </div>
    </v-card-text>

    <v-card-actions
      @click="toggleItem(item)"
      @mouseover="changeCursorStyle"
      @mouseleave="resetCursorStyle"
      :style="cursorStyle"
      v-if="item.price && this.$store.state.setup.cartOlymp !== '1'"
      class="mb-n3 mt-3"
      style="background-color: #f19515"
    >
      <div class="mx-auto letwork-card-price-text" v-text="itemPriceString(item)"></div>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mainMixin } from "@/mixins/mainMixin";
import Vue from "vue";
//import Window from "@/components/Window.vue";
//import Dimensions from "./Dimensions";
// import { mapState } from "vuex";

export default {
  name: "selectionCard",
  components: {},
  props: {
    item: {},
    itemType: {
      type: String,
      required: true
    },
    img_max_height: {
      type: Number,
      default: 218
    }
  },
  mixins: [mainMixin],
  data() {
    return {
      expanded: false,
      limitedTextLength: 150, // Maximale Länge des beschnittenen Textes
      text: this.item.description_short,
      hovered: false,  // Für Mouseover-Zustand
      dialog: false   // Für das Modal
    };
  },
  computed: {
    shouldShowReadMore() {
      return this.text.length > this.limitedTextLength;
    },
    limitedText() {
      return this.text.slice(0, this.limitedTextLength) + " ... ";
    },
    fullText() {
      return this.text;
    },
    selected() {
      if (this.$store.state.productData.model[this.itemType]?.multipleChoice) {
        let object = this.$store.state.configuration.customerConfig[this.itemType];
        // eslint-disable-next-line no-prototype-builtins
        //if (object && object.hasOwnProperty(this.item.id)) {
        if (object && object[this.item.id]) {
          return true;
        } else {
          return false;
        }
      } else {
        return (this.$store.state.configuration.customerConfig[this.itemType]?.id === this.item.id);
      }
    },
    active() {
      let deactivated = false;
      if (typeof this.$store.state.productData.model[this.itemType] != "undefined") {
        if (!this.item.active) {
          deactivated = true;
        }
        if (this.countPrividedItems(this.item.provided_items) > 0) {
          deactivated = this.isSetPrividedItem(this.item.provided_items);
        }
        if (this.countConflictItems(this.item.conflict_items) > 0) {
          deactivated = this.isSetConflictItem(this.item.conflict_items);
        }
      }
      //return this.$store.state.productData.model[this.itemType].items.id.active;
      return deactivated;
    },
    enquiryButton() {
      return this.item.enquiry_button ?? "";
    },
    // ...mapState("configuration", {
    //   selection: (state) => state.customerConfig,
    // }),
    setIdByAutomaticLoadingItem() {
      if (this.itemType === "materials") {
        return "material_" + this.item.id;
      } else if (this.itemType === "series") {
        return "series_" + this.item.id;
      } else if (this.itemType === "profiles") {
        return "profile_" + this.item.id;
      } else {
        return this.itemType + "_" + this.item.id;
      }
    },
    recommended() {
      if (this.$store.state.setup.cartOlymp === '1'){
        return '';
      } else {
        return this.item.recommended ?? "";
      }
    }
  },
  watch: {},
  methods: {
    onMouseOver() {
      this.hovered = true;
    },
    onMouseLeave() {
      this.hovered = false;
    },
    openModal() {
      this.dialog = true;  // Öffne das Modal
    },
    async toggleItem(item) {
      if (item.enquiry_button) {
        if (this.state.setup.cartOlymp !== '1') {
          window.location.href = item.enquiry_button;
        }
        return;
      }
      // sets the selection in store and handles either the loading of the next
      // element in the chain or does some magic to filter model specific categories
      //
      // caller: which elementType requests the load
      // selection: what item was selected
      const payload = {
        caller: this.itemType,
        selection: item
      };

      if (this.itemType === "profiles") {
        await this.$store.dispatch("setup/setOverlayLoader2", true);
      }


      if (!this.selected) {
        await this.$store.dispatch("productData/setItem", payload);
      } else {
        await this.$store.dispatch("productData/unSetItem", payload);
      }
      this.$root.$emit("sectionChanged");
      this.$emit("load");
      if (this.itemType === "openings") {
        Vue.set(this.$store.state.setup, "reloadProductOpeningId", item.id);
        Vue.set(this.$store.state.setup, "reloadDivisionId", item.parent_icon_id);
      }

      if (this.itemType === "materials") {
        let firstSeriesItem = this.$store.state.productData.series?.items[0];
        if (this.$store.state.configuration.customerConfig.materials !== null && this.$store.state.configuration.customerConfig.series !== null && firstSeriesItem.id > 0) {
          setTimeout(() => {
            this.clickAutomaticallySeries(firstSeriesItem.id);
          }, 250);
        }
      }

      if (this.itemType === "series" && !this.hasValidUuid) {
        let firstProfileItem = this.$store.state.productData.profiles?.items[0];
        if (this.$store.state.configuration.customerConfig.profiles !== null && firstProfileItem.id > 0) {
          setTimeout(() => {
            this.clickAutomaticallyProfile(firstProfileItem.id);
          }, 250);
        }
      }

      if (this.itemType === "profiles") {
        await this.$store.dispatch("setup/setOverlayLoader2", false);
        //Vue.set(this.$store.state.configuration, "customerConfig", this.$store.state.productData.customerConfigTemp);
      }
    },
    clickAutomaticallyProfile(profileId) {
      document.getElementById("profile_" + profileId).click();
    },
    clickAutomaticallyMaterial(materialId) {
      document.getElementById("material_" + materialId).click();
    },
    clickAutomaticallySeries(seriesId) {
      document.getElementById("series_" + seriesId).click();
    },
    clickAutomaticallyCategory(category, itemId) {
      document.getElementById(category + "_" + itemId).click();
    }
  }
  ,
  mounted() {
    // run automatically for load material and profile directly
    // let loadMaterialId = this.$store.getters["setup/getLoadMaterialId"];
    // let materialId = document.getElementById("material_" + loadMaterialId);
    // if (materialId && this.item.id == loadMaterialId) {
    //   this.$store.dispatch("setup/setOverlayLoader2", true);
    //   this.clickAutomaticallyMaterial(loadMaterialId);
    // }
    //
    // let loadProfileId = this.$store.getters["setup/getLoadProductId"];
    // let profileId = document.getElementById("profile_" + loadProfileId);
    // if (profileId && this.item.id == loadProfileId) {
    //   this.clickAutomaticallyProfile(loadProfileId);
    // }
    //
    // if (loadProfileId === 0) {
    //   this.$store.dispatch("setup/setOverlayLoader2", false);
    // }
  }

}
;
</script>
<style scoped>
.selected-card {
  border: 2px solid #F59100 !important; /* Setze die gewünschte Border-Farbe und -Breite */
}

.magnify-icon {
  position: absolute !important;
  top: 3px;
  left: 3px !important;
  color: #f59100 !important;
  border-radius: 50% !important;
  padding: 5px !important;
  cursor: pointer !important;
  z-index: 10 !important;
}

.magnify-icon-if-recommended{
  top: 22px !important;
}

.letwork-enquiry-button {
  background-color: #f59100 !important;
  color: #ffffff !important;
  font-size: 11px !important;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  border-radius: 0;
}

.letwork-titel {
  margin-top: 10px;
  font-size: 14px !important;
}

.letwork-text {
  font-size: 12px !important;
}

>>> .letwork-text p {
  padding: 0 5px 0 10px !important;
}

.letwork-card-titel {
  padding: 0 !important;
}

.letwork-card-text {
  min-height: 75px;
  padding: 0 !important;
}

.letwork-card-price-text {
  font-size: 12px !important;
}

.letwork-card {
  padding-bottom: 12px !important;
}

.letwork-card-image {
  border-radius: 4px 4px 0 0 !important;
}

.letwork-card-icon {
  position: absolute !important;
  right: -8px;
  top: -8px;
  border-radius: 2px;
  padding: 2px;
  background-color: #F59100;
  font-size: 25px;
  color: white !important;
  z-index: 2;
}

.letwork-card-text-read-more-button {
  color: #F59100;
}

.recommended-badge {
  background-color: #F59100;
  color: white;
  padding: 4px 6px;
  border-radius: 4px;
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 2;
  font-size: 12px;
  line-height: 12px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}
</style>
