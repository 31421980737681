import de from "./translations/de";
import en from "./translations/en";
import fr from "./translations/fr";
import nl from "./translations/nl";

export default {
  de,
  en,
  fr,
  nl,
};
